.wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    margin-top: 15px;
}
.order_header{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.guest{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    text-align: right;
    color: #8798AD;
}
.name{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    text-align: right;
    color: #2E5BFF;
    text-decoration: none;
    transition: .3s;
}
.name:hover{
    opacity: .8;
}
.wrapper{
    max-width: 778px;
}
.list_name{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #2E384D;
    margin-top: 24px;
}
.wrapper section{
    margin-bottom: 24px;
}
section .label{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #8798AD;
    margin-bottom: 8px;
}
.wrapper section hr{
    display: block;
    margin: 0;
    margin-top: 40px;
    width: 272px;
    height: 1px;
    background: #D9D9D9;
    border: none;
    outline: none;
}
.payer_list{
    margin-left: 0;
    padding-left: 0;
    list-style: none;
}
.item{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #242422;
    margin-top: 16px;
}
p.item{
    max-width: 142px;
    display: flex;
    justify-content: space-between;
}
.card{
    background: #FFFFFF;
    border: 1px solid #BBBFDB;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 16px;
    padding: 15px;
    padding-left: 30px;
}
.prod_info{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 20px;
    height: 100%;
}
.prod_title{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #2E384D;
}
.prod_amount{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #2E384D;
}
.prod_dropdown{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    color: #2E384D;
    cursor: pointer;
    transition: .3s;
}
.prod_dropdown:hover{
    opacity: .8;
}
.card img{
    max-width: 80px;
    object-fit: contain;
    display: inline-block;
}
.options{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-top: 20px;
}
.opt_label{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #8798AD;
    margin-bottom: 12px;
}
.opt_value{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #2E384D;
}

.invoice_input{
    position: absolute;
    z-index: -1;
    opacity: 0;
    left: 0;
    top: 0;
}
.invoice_label{
    background: #2E5BFF;
    border-radius: 4px;
    padding: 9.5px 39px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    transition: .3s;
    display: inline-block;
    white-space: nowrap;
}
.invoice_label:hover{
    opacity: .8;
}
.invoice_label svg{
    margin-right: 8px;
}
.invoice_wrapper{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    max-width: 400px;
}