.input{
    position: absolute;
    z-index: -1;
    opacity: 0;
    left: 0;
    top: 0;
}
.label{
    background: #2E5BFF;
    border-radius: 4px;
    padding: 9.5px 39px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    transition: .3s;
    display: inline-block;
    white-space: nowrap;
}
.label:hover{
    opacity: .8;
}
.label svg{
    margin-right: 8px;
}