/* This stylesheet generated by Transfonter (https://transfonter.org) on July 28, 2017 4:22 PM */

@font-face {
	font-family: 'Rubik';
	src: url('./fonts/Rubik/Rubik-BlackItalic.eot');
	src: local('Rubik Black Italic'), local('Rubik-BlackItalic'),
		url('./fonts/Rubik/Rubik-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Rubik/Rubik-BlackItalic.woff') format('woff'),
		url('./fonts/Rubik/Rubik-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik';
	src: url('./fonts/Rubik/Rubik-BoldItalic.eot');
	src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'),
		url('./fonts/Rubik/Rubik-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Rubik/Rubik-BoldItalic.woff') format('woff'),
		url('./fonts/Rubik/Rubik-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik';
	src: url('./fonts/Rubik/Rubik-Light.eot');
	src: local('Rubik Light'), local('Rubik-Light'),
		url('./fonts/Rubik/Rubik-Light.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Rubik/Rubik-Light.woff') format('woff'),
		url('./fonts/Rubik/Rubik-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('./fonts/Rubik/Rubik-Medium.eot');
	src: local('Rubik Medium'), local('Rubik-Medium'),
		url('./fonts/Rubik/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Rubik/Rubik-Medium.woff') format('woff'),
		url('./fonts/Rubik/Rubik-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('./fonts/Rubik/Rubik-Italic.eot');
	src: local('Rubik Italic'), local('Rubik-Italic'),
		url('./fonts/Rubik/Rubik-Italic.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Rubik/Rubik-Italic.woff') format('woff'),
		url('./fonts/Rubik/Rubik-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik';
	src: url('./fonts/Rubik/Rubik-Bold.eot');
	src: local('Rubik Bold'), local('Rubik-Bold'),
		url('./fonts/Rubik/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Rubik/Rubik-Bold.woff') format('woff'),
		url('./fonts/Rubik/Rubik-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('./fonts/Rubik/Rubik-LightItalic.eot');
	src: local('Rubik Light Italic'), local('Rubik-LightItalic'),
		url('./fonts/Rubik/Rubik-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Rubik/Rubik-LightItalic.woff') format('woff'),
		url('./fonts/Rubik/Rubik-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik';
	src: url('./fonts/Rubik/Rubik-Regular.eot');
	src: local('Rubik'), local('Rubik-Regular'),
		url('./fonts/Rubik/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Rubik/Rubik-Regular.woff') format('woff'),
		url('./fonts/Rubik/Rubik-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('./fonts/Rubik/Rubik-MediumItalic.eot');
	src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'),
		url('./fonts/Rubik/Rubik-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Rubik/Rubik-MediumItalic.woff') format('woff'),
		url('./fonts/Rubik/Rubik-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Rubik Mono One';
	src: url('./fonts/Rubik/RubikMonoOne-Regular.eot');
	src: local('Rubik Mono One Regular'), local('RubikMonoOne-Regular'),
		url('./fonts/Rubik/RubikMonoOne-Regular.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Rubik/RubikMonoOne-Regular.woff') format('woff'),
		url('./fonts/Rubik/RubikMonoOne-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('./fonts/Rubik/Rubik-Black.eot');
	src: local('Rubik Black'), local('Rubik-Black'),
		url('./fonts/Rubik/Rubik-Black.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Rubik/Rubik-Black.woff') format('woff'),
		url('./fonts/Rubik/Rubik-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
body{
	font-family: 'Rubik';
	overflow-x: hidden;
}
.fade-enter {
	opacity: 0;
	z-index: 1;
  }
  
  .fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 250ms ease-in;
  }
  