.card{
    width: 100%;
    padding: 19px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: move;
    position: relative;
}
.title{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #2E384D;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: .3s;
}
.title svg{
    margin-right: 12px;
}

.title:hover{
    opacity: .8;
}
.select{
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #2E384D;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.disabled{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .6);
    border-radius: 10px;
}