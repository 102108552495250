.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 62px;
}
.title{
    width: 400px;
}
.input{
    margin-bottom: 24px;
}
.btn{
    display: flex;
    width: 400px;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding: 0;
}