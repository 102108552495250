.item{
    position: relative;
    padding: 15px 65px;
    transition: .3s;
    display: flex;
    align-items: center;
}
.active{
    background: #FFFFFF;
}

.item.bage::after{
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    background: #38A466;
    border-radius: 100%;
    margin-left: 20px;
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 180px; */
}