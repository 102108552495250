.input{
    width: 100%;
    height: 45px;
    padding: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #2E384D;
    border: 1px solid #BBBFDB;
    box-sizing: border-box;
    border-radius: 5px;
    transition: .3s;
}
.input:active, .input:focus{
    transform: scale(1.01);
    outline: none;
}
.label{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #8798AD;
    display: block;
    margin-bottom: 8px;
    text-align: left;
}