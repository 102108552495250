.wrapper{
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ring {
display: inline-block;
position: relative;
width: 80px;
height: 80px;
}
.ring div {
box-sizing: border-box;
display: block;
position: absolute;
width: 64px;
height: 64px;
margin: 8px;
border: 4px solid #fff;
border-radius: 50%;
animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
border-color: #2E5BFF transparent transparent transparent;
}
.ring div:nth-child(1) {
animation-delay: -0.45s;
}
.ring div:nth-child(2) {
animation-delay: -0.3s;
}
.ring div:nth-child(3) {
animation-delay: -0.15s;
}
@keyframes ring {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
  