.panel{
    background: #FFFFFF;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    opacity: 0;
    z-index: -1;
}
.panel.active{
    opacity: 1;
    z-index: 5;
}
.btn{
    padding: 9.5px 90px;
}