.toggleWrapper{
    height: 20px;
}
.inactiveMixin {
    content: "";
    position: absolute;
    display: block;
  }
  .beforeAnimation {
    transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  }
  .afterAnimation {
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13), 0 3px 3px hsla(0, 0%, 0%, 0.05);
    transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  }
  .toggleWrapper input.mobileToggle {
    opacity: 0;
    position: absolute;
  }
  .toggleWrapper input.mobileToggle + label {
    position: relative;
    display: inline-block;
    user-select: none;
    transition: 0.4s ease;
    height: 20px;
    width: 35px;
    border-radius: 60px;
    cursor: pointer;

  }
  .toggleWrapper input.mobileToggle + label:before {
    content: "";
    position: absolute;
    display: block;
    transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
    height: 20px;
    width: 36px;
    top: 0;
    left: 0;
    background: #8798AD;
    border-radius: 30px;
  }
  .toggleWrapper input.mobileToggle + label:after {
    content: "";
    position: absolute;
    display: block;
    transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    background: #FFFFFF;
    height: 16px;
    width: 16px;
    top: 2px;
    left: 3px;
    border-radius: 60px;
  }
  .toggleWrapper input.mobileToggle:checked + label:before {
    background: #33AC2E;
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  }
  .toggleWrapper input.mobileToggle:checked + label:after {
    left: 17px;
  }