.wrapper{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #2E384D;
}
.counter{
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    color: #BBBFDB;
}
.more{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    color: #2E5BFF;
}