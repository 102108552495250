.compilations{
    margin-bottom: 64px;
}
.products{
    margin-bottom: 64px;
}
.desc{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #2E384D;
    /* max-width: 343px; */
}
.advertisingContent{
    display: flex;
    width: 100%;
}

.advertisingImage{
    margin-right: 40px;
    max-width: 232px;
}
.compilations .grid{
    margin-top: 24px;
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: 433px;
    grid-row-gap: 16px;
}
.goods_grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
}
.editor{
    width: 100%;
}
.bulk{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    height: 100%;
    display: flex;
    align-items: center;
    color: #E91B1B;
    white-space: nowrap;
    margin-left: 20px;
}
.cmp_item{
    display: flex;
    align-items: center;
    width: 100%;
}